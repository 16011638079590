<template>
  <div>
    <div class="searchNab">
      <h2>电子签章上传 (请到各地电子印章管理与服务平台下载)</h2>
    </div>
    <el-row :gutter="20">
      <el-col
        :span="4"
        v-for="(item, index) in CompanyList"
        :key="item.Id"
        v-loading="item.loading"
      >
        <div @click="focusIndex(index)">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="item.Signature" :src="item.Signature" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div>{{ item.Title }}签章</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: "",
      CompanyList: [],
      idx: "",
    };
  },
  mounted() {
    this.getComputedList();
  },
  methods: {
    focusIndex(index) {
      this.idx = index;
      console.log(this.idx);
    },
    // 公司
    getComputedList() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.$http
        .post("/Base_Manage/Base_Company/GetDataList", {
          Search: {
            condition: "CreatorId",
            keyword: userInfo.Id,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.CompanyList = res.Data;
          }
        });
    },
    handleAvatarSuccess(res, file) {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
      if (!isJPG) {
        return this.$message.error("请选择JPG/PNG格式图片");
      }
      var _this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 图片base64化
        var newUrl = this.result; //图片路径
        _this.imgUrl = newUrl;
        _this.saveData();
      };
    },
    saveData() {
      // 获取
      // let result = await this.$http.veriGet(
      //   "/auth/VeriRecord/publickey?Prefix=" + this.CompanyList[this.idx].Id
      // );
      // if (result.code != 200) return this.$message.error("上传失败,请重新尝试");
      // let { publicKey, signature } = result.data;
      // console.log(publicKey, signature);
      // // 加密
      // let encrypt = new this.$JsEncrypt();
      // encrypt.setPublicKey(publicKey); // 放置自己的公钥
      // console.log(encrypt.encrypt('acs'));
      // return;
      let str = "data:image/png;base64,";
      this.CompanyList[this.idx].loading = true;
      this.$http
        .post("/Base_Manage/Base_Company/SaveSignature", {
          CompanyId: this.CompanyList[this.idx].Id,
          Signature: this.imgUrl,
        })
        .then((res) => {
          this.CompanyList[this.idx].loading = false;
          if (res.Success) {
            this.$message.success("操作成功");
            this.$set(this.CompanyList[this.idx], "Signature", this.imgUrl);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
<style>
.el-col {
  margin-bottom: 14px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #fff;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 200px;
  display: block;
}
</style>